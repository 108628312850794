import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../apiHelper";

export const fetchNumbersData = createAsyncThunk(
  "/fetchNumbersData",
  async (params, { rejectWithValue, getState }) => {
    const state = getState();
    const numbers = state.events.numbers;
    try {
      const { data } = await apiRequest("/numbers", "GET", params);

      const newNumbers = numbers.map((currentNumber) => {
        const numberFromBackend = data.find((item) => {
          return item.id === currentNumber.id;
        });

        return { ...currentNumber, number: numberFromBackend.number };
      });

      return newNumbers;
    } catch (err) {
      console.warn("/fetchNumbersData", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  }
);

export const fetchJobsData = createAsyncThunk(
  "/fetchJobsData",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest("/jobs", "GET", params);

      return data;
    } catch (err) {
      console.warn("/fetchJobsData", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  }
);

export const fetchEmail = createAsyncThunk(
  "/fetchEmail",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest("/email/send", "POST", params, {
        "Content-Type": "multipart/form-data",
      });

      return data;
    } catch (err) {
      console.warn("/fetchEmail", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  }
);
