export const marketingStepsConfig = [
  {
    title: "marketing_step_1_title",
    description: "marketing_step_1_description",
  },
  {
    title: "marketing_step_2_title",
    description: "marketing_step_2_description",
  },
  {
    title: "marketing_step_3_title",
    description: "marketing_step_3_description",
  },
  {
    title: "marketing_step_4_title",
    description: "marketing_step_4_description",
  },
];
