import React from "react";
import MarketingSectionCard from "./MarketingSectionCard";
import { marketingStepsConfig } from "../configs/marketingStepsConfig";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { useTranslation } from "react-i18next";

function MarketingSectionComponent({ title, sectionImage }) {
  const { t: translate } = useTranslation();

  return (
    <div className="max-w-7xl mx-auto flex flex-col justify-center items-center px-[30px] py-[60px]">
      <h1 className="text-2xl md:text-4xl lg:text-5xl text-center font-bold mb-10">
        {translate(title)}
      </h1>

      <div className="flex flex-col lg:flex-row items-center">
        <div className="flex flex-wrap mb-[60px] lg:mb-0">
          {marketingStepsConfig.map((item, idx) => {
            return (
              <div key={idx} className="flex flex-col md:flex-col md:basis-1/2">
                <MarketingSectionCard
                  title={translate(item.title)}
                  description={translate(item.description)}
                  className={
                    idx === 3 ? "border-b-0" : "border-b-[1.5px] md:border-b-0"
                  }
                />
              </div>
            );
          })}
        </div>

        <img
          src={toAbsoluteUrl(sectionImage)}
          alt="section-marketing"
          className="w-[250px] md:w-[320px] lg:w-[350px] xl:w-[400px] rounded-3xl"
        />
      </div>
    </div>
  );
}

export default MarketingSectionComponent;
