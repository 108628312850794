import React from "react";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { wmsConfig } from "../configs/wmsConfig";
import SliderAndContentComponent from "../components/PagesComponents/SliderAndContentComponent";
import HeroVideoComponent from "../components/PagesComponents/HeroVideoComponent";
import MarketingSectionCard from "../components/MarketingSectionCard";
import SelectableListWithContent from "../components/SelectableListWithContent";
import HalfBgWithText from "../components/PagesComponents/HalfBgWithText";

function Wms() {
  return (
    <div className="min-h-screen">
      <HeroVideoComponent
        height={"100vh"}
        wrapperClassName="mb-[100px]"
        videoUrl={toAbsoluteUrl("/assets/wmsPhotos/video-2a.mp4")}
        title={"Werehouse Management System"}
      />

      <section className="bg-gray-50 py-16 px-6 lg:px-20 text-center">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-primary-color text-2xl md:text-4xl lg:text-5xl drop-shadow  font-bold text-center py-[60px]">
            {wmsConfig.title}
          </h2>
          <p className={"md xl:text-base font-medium  mb-6"}>
            {wmsConfig.description}
          </p>
        </div>
      </section>

      <section>
        <h2 className="text-primary-color text-2xl md:text-4xl lg:text-5xl drop-shadow  font-bold text-center py-[60px]">
          {wmsConfig.aplicationsTitle}
        </h2>

        <div className="max-w-7xl mx-auto">
          {wmsConfig.aplications.map((item, idx) => {
            const images = item.images;
            return (
              <SliderAndContentComponent
                key={`wms-${idx}`}
                title={item.title}
                type={`${idx % 2 ? "reverse" : ""}`}
                descriptions={item.descriptions.map((description, idx) => {
                  return (
                    <p
                      key={`description-${idx}`}
                      className={"text-sm xl:text-base font-medium  mb-6"}
                    >
                      {description}
                    </p>
                  );
                })}
                sliderData={images}
              />
            );
          })}
        </div>
      </section>

      <section>
        <h2 className="text-primary-color text-2xl md:text-4xl lg:text-5xl drop-shadow  font-bold text-center py-[60px]">
          {wmsConfig.modulesTitle}
        </h2>
        <div className="max-w-7xl mx-auto flex flex-col justify-center items-center px-[30px] pb-[60px]">
          <SelectableListWithContent categories={wmsConfig.modules} />
        </div>
      </section>

      <section>
        <h2 className="text-primary-color text-2xl md:text-4xl lg:text-5xl drop-shadow  font-bold text-center py-[60px]">
          {wmsConfig.featuresTitle}
        </h2>

        <div className="max-w-7xl mx-auto flex flex-col justify-center items-center px-[30px] pb-[60px]">
          <div className="flex flex-wrap mb-[60px] lg:mb-0">
            {wmsConfig.features.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="flex flex-col md:flex-col md:basis-1/2"
                >
                  <MarketingSectionCard
                    title={item.title}
                    description={item.description}
                    textClassName={"text-sm xl:text-base font-medium"}
                    className={
                      idx === 3
                        ? "border-b-0"
                        : "border-b-[1.5px] md:border-b-0"
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <HalfBgWithText
        height={500}
        title={wmsConfig.footer.title}
        description={wmsConfig.footer.description}
        buttonText={"Contacteaza-ne"}
        buttonUrl="/contact"
        image={toAbsoluteUrl("/assets/wmsPhotos/putaway_2.jpg")}
        contentClassName="bg-primary-color"
        buttonVariant="secondary"
      />
      <div className="mb-2" />
    </div>
  );
}

export default Wms;
