import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import OurProjects from "../components/OurProject";
import Dropdown from "../components/Dropdown";
import {
  ourProjectsConfig,
  projectsCategories,
  projectsTechnologies,
} from "../configs/ourProjectsConfig";
import useRoutesHelper from "../hooks/useRoutesHelper";

function Projects() {
  const { t: translate } = useTranslation();
  const { routeParams } = useRoutesHelper();

  const initialTechnology = projectsTechnologies[0];

  const projectsCategoriesFilteredDropdownOptions = projectsCategories.filter(
    (category) => category.icon
  );

  const [selectedCategory, setSelectedCategory] = useState(
    projectsCategoriesFilteredDropdownOptions[0]
  );

  const [selectedTechnology, setSelectedTechnology] =
    useState(initialTechnology);

  useEffect(() => {
    const selectedCategoryFromWorkCard =
      projectsCategoriesFilteredDropdownOptions.find(
        (category) => category?.value?.toString() === routeParams?.category
      );

    if (selectedCategoryFromWorkCard) {
      setSelectedCategory(selectedCategoryFromWorkCard);
    }
  }, [projectsCategoriesFilteredDropdownOptions, routeParams?.category]);

  const filteredTechnologiesDropdownOptions = useMemo(
    () =>
      projectsTechnologies.filter((technologiesDropdownOption) => {
        // if the category is selected as all, we show all the technologies
        if (selectedCategory.value === 0) {
          return true;
        }
        // if the technology is all (will not have categoriesValue), we show it all the time (the option in the dropdown)
        if (!technologiesDropdownOption.categoriesValue) {
          return true;
        }
        // here we check if the technology is included in the category
        return technologiesDropdownOption.categoriesValue.includes(
          selectedCategory.value
        );
      }),
    [selectedCategory.value]
  );

  let filteredProjects = ourProjectsConfig;

  // we check if the selected category will have a value greater than 0 (0 is the all value, so we will pass the filter)
  if (selectedCategory.value) {
    // if a category is selected, we filter the projects and replace the filteredProjects value
    filteredProjects = filteredProjects.filter((ourProject) => {
      return ourProject.categories.includes(selectedCategory.value);
    });
  }

  // we check if the selected technology will have a value greater than 0 (0 is the all value, so we will pass the filter)
  if (selectedTechnology.value) {
    // if a technology is selected, we filter the projects and replace the filteredProjects value
    filteredProjects = filteredProjects.filter((ourProject) => {
      return ourProject.technologies.includes(selectedTechnology.value);
    });
  }

  const handleSelectedCategory = (item) => {
    setSelectedCategory(item);
    // if we change the category, we need to reset the technology too
    // so we will not have setted the technology for a different category
    setSelectedTechnology(initialTechnology);
  };

  return (
    <div className="min-h-screen">
      <HeroComponent
        height={650}
        title={translate("hero_projects_title")}
        backgroundImage={toAbsoluteUrl("/assets/SSNTAssets/project-pic.jpg")}
        wrapperClassName="bg-center"
      />

      <div className="max-w-[1450px] mx-auto pt-[100px]">
        <div className="flex flex-col md:flex-row items-center mx-4">
          <Dropdown
            className="mb-2 md:mb-0"
            selectionTitle="Categories"
            dropdownOptions={projectsCategoriesFilteredDropdownOptions}
            selectedOption={selectedCategory}
            setSelectedOption={handleSelectedCategory}
          />

          <Dropdown
            className="mx-3"
            selectionTitle="Categories"
            dropdownOptions={filteredTechnologiesDropdownOptions}
            selectedOption={selectedTechnology}
            setSelectedOption={setSelectedTechnology}
          />
        </div>
        <OurProjects projects={filteredProjects} />
      </div>
    </div>
  );
}

export default Projects;
