import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown({
  className,
  dropdownOptions,
  selectedOption,
  setSelectedOption,
}) {
  const { t: translate } = useTranslation();

  return (
    <Menu
      as="div"
      className={`${className} relative inline-block text-left w-[250px]`}
    >
      <MenuButton
        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-4 py-4 h-[50px] text-sm font-semibold text-gray-900 shadow-sm 
          whitespace-nowrap
          ring-1 ring-inset ring-gray-300 hover:bg-gray-50 items-center"
      >
        {selectedOption.icon && (
          <img
            src={selectedOption.icon}
            alt="selectedOption"
            className="w-[28px] mr-1"
          />
        )}{" "}
        {translate(selectedOption.label)}
        <ChevronDownIcon
          className="-mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </MenuButton>

      <MenuItems
        transition
        className="absolute z-10 mt-2 w-[250px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in "
      >
        <ul className="py-1">
          {dropdownOptions.map((dropdownOption, idx) => {
            return (
              <MenuItem key={idx}>
                {({ focus }) => (
                  <li
                    className={`cursor-pointer flex items-center
                      ${classNames(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}`}
                    onClick={() => setSelectedOption(dropdownOption)}
                  >
                    {dropdownOption.icon && (
                      <img
                        src={dropdownOption.icon}
                        alt="selectedOption"
                        className="w-[22px] mr-2"
                      />
                    )}{" "}
                    {translate(dropdownOption.label)}
                  </li>
                )}
              </MenuItem>
            );
          })}
        </ul>
      </MenuItems>
    </Menu>
  );
}
