export const companyNumbersConfig = [
  {
    id: 1,
    icon: "/assets/SSNTAssets/OurNumbersIcons/business.svg",
    number: "2020",
    text: "company_numbers_business_text",
  },
  {
    id: 2,
    icon: "/assets/SSNTAssets/OurNumbersIcons/experts.svg",
    number: "12",
    text: "company_numbers_experts_text",
  },
  {
    id: 3,
    icon: "/assets/SSNTAssets/OurNumbersIcons/senior-developer.svg",
    number: "50% +",
    text: "company_numbers_developers_text",
  },
  {
    id: 4,
    icon: "/assets/SSNTAssets/OurNumbersIcons/project-management.svg",
    number: "15",
    text: "company_numbers_projects_text",
  },

  {
    id: 5,
    icon: "/assets/SSNTAssets/OurNumbersIcons/longest-project.svg",
    number: "2021",
    text: "company_numbers_longest_project_text",
  },
  {
    id: 6,
    icon: "/assets/SSNTAssets/OurNumbersIcons/customer-rate.svg",
    number: "90%",
    text: "company_numbers_retention_rate_text",
  },
];
