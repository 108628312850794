import React from "react";
import KTSVG from "../utils/KTSVG";
import { colorsConfig } from "../configs/colorsConfig";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

function ApproachCustomSoftwareCard({
  icon,
  title,
  description,
  buttonText,
  buttonUrl,
}) {
  const navigate = useNavigate();
  const handleGoToPage = () => {
    navigate(buttonUrl);
  };

  return (
    <div className="flex flex-col justify-start items-start select-none lg:mx-1.5 border-l-2 border-primary-light-color py-[36px] px-[35px]">
      {icon && (
        <KTSVG
          path={icon}
          fill={colorsConfig.black}
          svgClassName="w-[80px] h-[80px] "
        />
      )}
      <h5 className="font-bold text-[20px] drop-shadow-sm mt-3 mb-5 lg:mb-12">
        {title}
      </h5>
      {description && (
        <p className="text-[18px] font-light drop-shadow mb-[50px] h-[180px]">
          {description}
        </p>
      )}

      {buttonText && <Button onClick={handleGoToPage}>{buttonText}</Button>}
    </div>
  );
}

export default ApproachCustomSoftwareCard;
