import React from "react";
import Button from "./Button";

function Content({
  title,
  description,
  descriptions,
  buttonText,
  wrapperClassName,
  contentClassName,
  buttonVariant,
  contentType,
  descriptionColor,
  descriptionClass,
  onClick,
}) {
  return (
    <div className={`${wrapperClassName}`}>
      <div className={`${contentClassName} flex flex-col select-none`}>
        <h2
          className={`text-xl lg:text-2xl xl:text-3xl  ${
            contentType === "secondary"
              ? "text-white drop-shadow"
              : "text-primary-color"
          }  font-bold mb-6`}
        >
          {title}
        </h2>
        {description && (
          <p
            className={`${
              contentType === "secondary"
                ? "text-white drop-shadow"
                : `${descriptionColor} md:text-black`
            }  text-sm xl:text-base font-medium ${descriptionClass} mb-6`}
          >
            {description}
          </p>
        )}

        {descriptions}

        {buttonText && (
          <Button
            onClick={onClick}
            variant={`${
              contentType === "secondary" ? "secondary" : buttonVariant
            }`}
            className="!h-fit !w-fit"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Content;
