export const wmsConfig = {
  title: "Despre",
  description:
    "Sistem de Management al Depozitelor (WMS) pentru domeniul farmaceutic este o platforma software specializata, proiectata pentru a gestiona depozitarea, miscarea si urmarirea produselor farmaceutice intr-un depozit sau centru de distributie. Acesta ajuta la asigurarea respectarii reglementarilor stricte din industrie, inclusiv a celor legate de controlul temperaturii, acuratetea stocurilor si trasabilitate. In sectorul farmaceutic, un WMS trebuie sa respecte cerinte specifice pentru a gestiona in mod eficient produsele extrem de sensibile si reglementate.",
  aplicationsTitle: "Aplicatii",
  aplications: [
    {
      id: 1,
      title: "Panou de administrare",
      descriptions: [
        "• Panoul de administrare ofera o interfata centralizata pentru gestionarea operatiunilor sistemului.",
        "• Permite utilizatorilor sa monitorizeze stocurile in timp real si sa genereze rapoarte detaliate.",
        "• Functionalitatile includ gestionarea utilizatorilor, permisiunilor si configurarea sistemului.",
        "• Interfata este intuitiva, facilitand navigarea si accesul rapid la informatiile necesare.",
        "• Ofera optiuni de personalizare pentru a se adapta nevoilor specifice ale fiecarui utilizator.",
      ],
      images: [
        "/assets/wmsPhotos/verificare_3.jpg",
        "/assets/wmsPhotos/verificare_2.jpg",
        "/assets/wmsPhotos/verificare_1.jpg",
      ],
    },
    {
      id: 2,
      title: "Aplicatie mobila",
      descriptions: [
        "• Aplicatia mobila permite utilizatorilor sa gestioneze operatiunile direct de pe dispozitivele lor mobile.",
        "• Ofera acces la informatii despre stocuri, comenzi si livrari in timp real.",
        "• Interfata prietenoasa asigura o experienta de utilizare placuta si eficienta.",
        "• Permite scanarea codurilor de bare pentru o gestionare rapida a produselor.",
      ],
      images: [
        "/assets/wmsPhotos/putaway_1.jpg",
        "/assets/wmsPhotos/putaway_2.jpg",
      ],
    },
  ],
  modulesTitle: "Module",
  modules: [
    {
      id: 1,
      title: "Receptie",
      descriptions: [
        "• Sosirea si verificarea initiala a transportului: Marfurile sunt descarcate si sunt aduse in zona de receptie a depozitului.",
        "• Verificarea documentelor: Personalul din receptie verifica documentele de transport (facturi, avize de expeditie, certificate de conformitate) pentru a se asigura ca marfa livrata corespunde cu ceea ce a fost comandat.",
        "• Inspectia externa a transportului: Verificarea integritatii ambalajului exterior pentru a detecta eventuale daune sau nereguli aparute in timpul transportului.",
        "• Etichetarea si identificarea: Fiecare produs este verificat pentru a se asigura ca este corect etichetat, iar detaliile de identificare, cum ar fi lotul, seria si data de expirare, sunt corect marcate si conforme cu cerintele legale.",
        "• Verificarea loturilor si a datelor de expirare: Fiecare lot si data de expirare sunt inregistrate in sistem pentru a permite trasabilitatea completa. Loturile si seriile sunt validate pentru a se asigura ca se potrivesc cu documentele de livrare.",
        "• Inregistrarea in sistemul de management al depozitului (WMS): Toate informatiile despre produsele receptionate (loturi, date de expirare, cantitati, etc.) sunt inregistrate in sistemul WMS.",
        "• Generarea etichetelor interne: In cazul in care depozitul foloseste etichete interne pentru managementul stocurilor, acestea sunt generate si atasate produselor.",
        "• Generarea de task-uri de depozitare in functie de tipul produsului (temperatura controlata, substante periculoase, etc.) si cantitatea de depozitat.",
      ],
    },
    {
      id: 2,
      title: "Depozitare",
      descriptions: [
        "• Depozitarea propriu-zisa: Produsele sunt mutate in locatiile desemnate, asigurandu-se ca respecta conditiile de depozitare corespunzatoare (temperatura, umiditate, etc.).",
        "• Sistemul determina si recomanda transferul produselor catre celulele de depozitare in functie de cantitati, disponibilitate, prezenta la raft.",
      ],
    },
    {
      id: 3,
      title: "Gestionarea neconformitatilor",
      descriptions: [
        "• Returnarea produselor defecte: Daca se constata produse neconforme sau deteriorate, acestea sunt plasate in carantina sau returnate catre furnizor.",
        "• Documentarea incidentelor: Orice neconformitate este documentata si raportata pentru a asigura o trasabilitate completa si pentru a evita probleme similare in viitor.",
        "• Carantina produse: Izolarea in zone special desemnate din depozit a produselor care nu pot fi utilizate sau distribuite imediat dupa receptie, pana cand se efectueaza verificarile necesare si se confirma conformitatea acestora cu reglementarile de siguranta si calitate.",
        "• Daca produsele trec toate testele de conformitate si sunt considerate sigure si conforme, ele sunt eliberate din carantina si sunt mutate in stocul disponibil pentru distributie sau utilizare.",
        "• Daca produsele nu trec verificarile necesare sau sunt considerate neconforme (deteriorate, expirate, contrafacute), acestea sunt fie returnate furnizorului, fie distruse, in conformitate cu reglementarile locale si internationale.",
      ],
    },
    {
      id: 4,
      title: "Colectare",
      descriptions: [
        "• Colectarea consta in selectarea si pregatirea produselor din depozit pentru a fi expediate in functie de comenzile primite.",
        "• Colectarea este precisa si bine organizata pentru a respecta reglementarile stricte din industria farmaceutica privind trasabilitatea, siguranta si acuratetea.",
        "• Sistemul preia comenzile emise in ERP, le comaseaza in functie de client pentru optimizarea timpului de colectare si reducerea cheltuielilor de transport.",
        "• Personalul din depozit este informat cu privire la sarcinile de colectare prin intermediul dispozitivelor mobile asociate in WMS.",
      ],
    },
    {
      id: 5,
      title: "Verificare, impachetare si etichetare",
      descriptions: [
        "• Verificare dubla: Dupa colectarea produselor, acestea sunt verificate din nou pentru a asigura ca toate articolele corecte au fost selectate in cantitatea necesara.",
        "• Verificarea este asigurata de catre un alt angajat sau prin scanarea codurilor de bare pentru a confirma acuratetea.",
        "• WMS urmareste intregul proces de colectare si detecteaza orice eroare care este corectata inainte de expediere.",
      ],
    },
    {
      id: 6,
      title: "Inventar",
      descriptions: [
        "• Inventarul periodic: Procesul de verificare a stocurilor la intervale regulate (lunar, trimestrial, anual), prin compararea stocului fizic cu cel inregistrat in WMS.",
        "• Inventarul continuu (ciclic): Verificarea continua si regulata a unor portiuni de stoc, de obicei zilnic sau saptamanal, pentru a asigura acuratetea datelor.",
        "• Inventarul la cerere: Acesta este efectuat atunci cand exista suspiciuni de discrepante intre stocul inregistrat si cel fizic sau in cazul unor audituri externe.",
      ],
    },
    {
      id: 7,
      title: "Expeditie",
      descriptions: [
        "• Expeditia este ultima etapa a procesului logistic si consta in pregatirea si plasarea coletelor in zonele de expeditie pentru a fi preluate de livratori.",
        "• La preluarea coletelor sunt generate si documentele de transport.",
      ],
    },
  ],
  featuresTitle: "Caracteristici",
  features: [
    {
      title: "Conformitate cu reglementarile",
      description:
        "Asigura faptul ca procesele din depozit respecta liniile directoare stabilite de autoritatile de reglementare.",
    },
    {
      title: "Serializare si trasabilitate",
      description:
        "Permite urmarirea unitatilor individuale sau a loturilor prin serializare, pentru a preveni contrafacerea, esentiala pentru siguranta medicamentelor.",
    },
    {
      title: "Piste de audit",
      description:
        "Pastreaza inregistrari detaliate ale miscarilor stocurilor pentru auditurile de reglementare, asigurand transparenta si responsabilitatea.",
    },
    {
      title: "Controlul temperaturii si al mediului",
      description:
        "Monitorizeaza produsele sensibile la temperatura, cum ar fi vaccinurile sau biologicele, pentru a mentine eficacitatea si conditiile corecte de stocare.",
    },
    {
      title: "Gestionarea loturilor si a datelor de expirare",
      description:
        "Sistemul monitorizeaza durata de viata a produselor, cu alerte automate pentru datele de expirare iminente, si aplica regulile FEFO pentru livrare.",
    },
    {
      title: "Acuratetea si controlul stocurilor",
      description:
        "Pastreaza inregistrari detaliate ale loturilor si actualizari in timp real ale stocurilor, facilitand retragerile si reaprovizionarea automata.",
    },
    {
      title: "Securitate si acces controlat",
      description:
        "Ofera niveluri suplimentare de securitate pentru substantele controlate si acces bazat pe roluri pentru zonele sensibile.",
    },
    {
      title: "Completarea comenzilor si expedierea",
      description:
        "Sustine preluarea precisa si ambalarea comenzilor, mentinand acuratetea si respectarea reglementarilor, precum si gestionarea retragerilor de produse.",
    },
    {
      title: "Integrarea cu alte sisteme",
      description:
        "Se integreaza perfect cu ERP, TMS si alte sisteme pentru vizibilitate completa a lantului de aprovizionare si generare de rapoarte.",
    },
    {
      title: "Asigurarea calitatii",
      description:
        "Permite carantinarea produselor suspecte sau deteriorate, gestionarea retragerilor si defectelor pentru asigurarea calitatii.",
    },
  ],
  footer: {
    title:
      "Cauti o estimare precisa a costurilor pentru implementarea unui sistem de management al depozitului?",
    description:
      "Suntem aici sa te ajutam cu toate informatiile necesare pentru o estimare exacta a costurilor pentru implementarea unui WMS. Nu ezita sa ne contactezi, iar echipa noastra iti va raspunde in cel mai scurt timp!",
  },
};
