import React from "react";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {
  const { t: translate } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <div className="bg-primary-color text-[.75em]">
      <div
        className="max-w-7xl mx-auto flex flex-col items-center justify-evenly  py-[40px] 
      lg:flex-row"
      >
        <div
          className="w-full flex flex-col justify-center items-center
            lg:flex-row
            lg:items-start 
            lg:justify-evenly text-center lg:text-left"
        >
          <div className="flex flex-col text-white mb-5">
            <Link to="/" className="mx-auto py-2">
              <img
                className="w-[100px]"
                src={toAbsoluteUrl("/assets/SSNTAssets/ssnt-logo.svg")}
                alt="ssnt-logo"
              />
            </Link>

            <h5 className="text-blue-200 font-medium">
              © SC SOFTWARE SOLUTIONS NETWORK TOOLS S.R.L - {currentYear}
            </h5>
          </div>
          <div className="flex flex-col text-white  mb-5">
            <h5 className="text-blue-200 font-medium  mb-[7px]">
              {translate("footer_section_1_title")}
            </h5>
            <a
              href="https://anpc.ro/"
              target="_blank"
              rel="noreferrer"
              alt="protectia-consumatorului"
              className="cursor-pointer hover:underline"
            >
              {translate("footer_section_1_subtitle_1")}
            </a>
            <Link to="/privacy-policy" className="hover:underline">
              {translate("footer_section_1_subtitle_2")}
            </Link>
            <Link to="/cookies-policy" className="hover:underline">
              {translate("footer_section_1_subtitle_3")}
            </Link>
          </div>

          <div className="flex flex-col text-white mb-5">
            <h5 className="text-blue-200 font-medium mb-[7px]">SSNT</h5>

            <Link to="/contact" className="cursor-pointer hover:underline">
              {translate("footer_section_2_subtitle_1")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
