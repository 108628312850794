import React, { useState } from "react";
import Button from "./Button";
import { useDispatch } from "react-redux";
import { fetchEmail } from "../redux/api/events.api";
import Input from "./Input";

function UploadCV({ buttonText, subject = "Angajare" }) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [email, setEmail] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const MAX_SIZE_MB = 25;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

    // Check if the selected file is a PDF
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size <= MAX_SIZE_BYTES) {
          setSelectedFile(file);
          setErrorMessage("");
        } else {
          setSelectedFile(null);
          setErrorMessage(
            `Te rog ataseaza un fiser PDF mai mic de ${MAX_SIZE_MB}MB!`
          );
        }
      } else {
        setSelectedFile(null);
        setErrorMessage("Te rog ataseaza un fiser PDF!");
      }
    }
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    dispatch(
      fetchEmail({
        attachment: selectedFile,
        subject: subject,
        email: email,
      })
    );
    setSelectedFile(null);
    setSuccessMessage("Fisierul a fost trimis cu succes!");
  };

  setTimeout(() => {
    if (errorMessage || successMessage) {
      setErrorMessage("");
    }
  }, 3000);

  if (successMessage) {
    return (
      <p
        className={
          "text-green-500  text-sm xl:text-base text-center font-semibold my-3"
        }
      >
        {successMessage}
      </p>
    );
  }

  return (
    <form className="flex flex-col items-center" onSubmit={handleSendEmail}>
      <input
        type="file"
        id="uploadCV"
        accept="application/pdf"
        className="hidden"
        onChange={handleFileChange}
      />

      <label htmlFor="uploadCV" className="btn btn-primary">
        {buttonText}
      </label>

      {(errorMessage || successMessage) && (
        <p
          className={`${
            errorMessage ? "text-red-500" : "text-green-500"
          } text-sm xl:text-base text-center font-semibold my-3`}
        >
          {errorMessage ? errorMessage : successMessage}
        </p>
      )}

      {selectedFile && (
        <div className="bg-gray-50 flex flex-col justify-center items-center rounded-xl p-5 lg:w-[500px] mt-5">
          <Input
            type="email"
            id="email"
            labelName="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            deleteInputValue={() => setEmail("")}
            inputBgColor={"bg-white"}
          />
          <p className="text-black text-sm xl:text-base text-center font-semibold my-6">
            Fisierul selectat:
            <span className="text-primary-color"> {selectedFile.name}</span>
          </p>
          <Button type="submit">Trimite</Button>
        </div>
      )}
    </form>
  );
}

export default UploadCV;
