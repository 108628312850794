import "./App.css";
import "swiper/css";
import "swiper/css/bundle";

import "./lang/i18n";

import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import ScrollToTop from "./utils/ScrollToTop";
import Footer from "./components/Footer";
import CareerJobPage from "./pages/CareerJobPage";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy";
import Navbar from "./components/Navbar";
import Projects from "./pages/Projects";
import Marketing from "./pages/Marketing";
import Wms from "./pages/Wms";

function App() {
  return (
    <div className="font-montserrat max-w-[2600px] bg-[rgb(249, 250, 250)] mx-auto ">
      <BrowserRouter>
        <ScrollToTop />

        <Navbar />

        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route path="/career/:id" element={<CareerJobPage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/marketing" element={<Marketing />} />
            <Route path="/wms" element={<Wms />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />

            <Route path="*" element={<Home />} />
          </Routes>
        </div>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
