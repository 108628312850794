import React from "react";
import { useTranslation } from "react-i18next";

const privacyPolicySections = [
  {
    title: "policy_section_title_1",
    description: "policy_section_description_1",
  },
  {
    title: "policy_section_title_2",
    description: "policy_section_description_2",
  },
  {
    title: "policy_section_title_3",
    description: "policy_section_description_3",
  },
  {
    title: "policy_section_title_4",
    description: "policy_section_description_4",
  },
  {
    title: "policy_section_title_5",
    description: "policy_section_description_5",
  },
  {
    title: "policy_section_title_6",
    description: "policy_section_description_6",
  },
  {
    title: "policy_section_title_7",
    description: "policy_section_description_7",
  },
  {
    title: "policy_section_title_8",
    description: "policy_section_description_8",
  },
  {
    title: "policy_section_title_9",
    description: "policy_section_description_9",
  },
  {
    title: "policy_section_title_10",
    description: "policy_section_description_10",
  },
  {
    title: "policy_section_title_11",
    description: "policy_section_description_11",
  },
  {
    title: "policy_section_title_12",
    description: "policy_section_description_12",
  },
  {
    title: "policy_section_title_13",
    description: "policy_section_description_13",
  },
];

function PrivacyPolicy() {
  const { t: translate } = useTranslation();
  return (
    <div className="max-w-[1300px] mx-auto pt-[55px] px-3 my-[55px]">
      <h1 className="max-w-7xl mx-auto text-4xl md:text-5xl lg:text-6xl drop-shadow mb-5 font-bold text-center">
        {translate("policy_title")}
      </h1>

      <p>{translate("policy_description")}</p>

      <div className="py-5">
        {privacyPolicySections.map((section, idx) => {
          return (
            <div key={`section-${idx}`} className="mb-4">
              <h1 className="text-3xl font-bold mb-2">
                {`${idx + 1}. ${translate(section.title)}`}
              </h1>
              <p>{translate(section.description)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
