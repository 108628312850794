export const marketingConfig = [
  {
    id: 1,
    title: "marketing_google_ads_title",
    descriptions: [
      "marketing_google_ads_description_1",
      "marketing_google_ads_description_2",
      "marketing_google_ads_description_3",
      "marketing_google_ads_description_4",
      "marketing_google_ads_description_5",
    ],
    images: [
      "/assets/SSNTAssets/MarketingPictures/google-search.jpg",
      "/assets/SSNTAssets/MarketingPictures/google-leads.jpg",
      "/assets/SSNTAssets/MarketingPictures/google-youtube.jpg",
      "/assets/SSNTAssets/MarketingPictures/google-shopping.jpg",
      "/assets/SSNTAssets/MarketingPictures/google-app.jpg",
    ],
  },

  {
    id: 2,
    title: "marketing_meta_ads_title",
    descriptions: [
      "marketing_meta_ads_description_1",
      "marketing_meta_ads_description_2",
      "marketing_meta_ads_description_3",
      "marketing_meta_ads_description_4",
    ],
    image: "/assets/SSNTAssets/MarketingPictures/meta.jpeg",
  },
  {
    id: 3,
    title: "marketing_why_choose_us_title",
    descriptions: [
      "marketing_why_choose_us_description_1",
      "marketing_why_choose_us_description_2",
      "marketing_why_choose_us_description_3",
      "marketing_why_choose_us_description_4",
    ],
    image: "/assets/SSNTAssets/MarketingPictures/why-us.jpg",
  },
  {
    id: 4,
    title: "marketing_promotional_strategy_title",
    descriptions: [
      "marketing_promotional_strategy_description_1",
      "marketing_promotional_strategy_description_2",
      "marketing_promotional_strategy_description_3",
      "marketing_promotional_strategy_description_4",
      "marketing_promotional_strategy_description_5",
    ],
    image: "/assets/SSNTAssets/MarketingPictures/strategy.jpg",
  },
];
