import React, { useState } from "react";

const SelectableListWithContent = ({ categories }) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categories[0]?.id || null
  );
  const [isContentVisible, setIsContentVisible] = useState(true);
  const [content, setContent] = useState(categories[0]);

  const handleCategoryClick = (id) => {
    if (id !== selectedCategoryId) {
      setIsContentVisible(false);
      setTimeout(() => {
        setSelectedCategoryId(id);
        setContent(categories.find((category) => category.id === id));
        setIsContentVisible(true);
      }, 300);
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-96 w-full bg-gray-50">
      <div
        className="w-full md:w-1/4 bg-white p-6 m-2  md:min-h-96	 md:overflow-y-auto
                   flex md:block space-x-4 md:space-x-0 overflow-x-auto"
      >
        <nav className="flex md:flex-col space-y-0 md:space-y-4">
          {categories.map((category) => (
            <div
              key={category.id}
              onClick={() => handleCategoryClick(category.id)}
              className={`cursor-pointer p-2 rounded-lg text-lg font-semibold text-gray-700
                ${
                  selectedCategoryId === category.id
                    ? "bg-gray-200 text-primary-color"
                    : ""
                }
                hover:bg-gray-100 transition-all duration-300 ease-in-out`}
              style={{ opacity: selectedCategoryId === category.id ? 1 : 0.6 }} // Change opacity when selected
            >
              {category.title}
            </div>
          ))}
        </nav>
      </div>

      <div className="w-full md:w-3/4 p-8">
        <div
          className={`bg-gray-100 p-6 rounded-lg transition-opacity duration-500 ease-in-out ${
            isContentVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          <h2 className="text-primary-color text-2xl font-semibold mb-4">
            {content.title}
          </h2>
          {content.descriptions.map((description, index) => (
            <p key={index} className="text-gray-700 mb-2">
              {description}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectableListWithContent;
