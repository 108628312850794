// if we add icon, the category will be displayed in the dropdown filter from projects
// if we add image, the category will be displayed on the home page
// the page will be used as a redirect when you click a category
export const projectsCategories = [
  {
    value: 0,
    label: "projects_page_category_filter_all",
    icon: "/assets/SSNTAssets/category-options.svg",
  },
  {
    value: "web-dev",
    label: "Web Development",
    icon: "/assets/SSNTAssets/web.svg",
    image: "/assets/SSNTAssets/OurWorkIcons/web-development.svg",
    page: "/projects",
    description: "work_card_web_description",
  },

  {
    value: "mobile-dev",
    label: "Mobile Development",
    icon: "/assets/SSNTAssets/mobile.svg",
    image: "/assets/SSNTAssets/OurWorkIcons/mobile-development.svg",
    page: "/projects",
    description: "work_card_mobile_description",
  },

  {
    value: "business-apps",
    label: "Business Applications",
    icon: "/assets/SSNTAssets/business.svg",
    image: "/assets/SSNTAssets/OurWorkIcons/business-applications.svg",
    page: "/projects",
    description: "work_card_business_description",
  },
  {
    value: "digital-marketing",
    label: "Digital Marketing",
    image: "/assets/SSNTAssets/OurWorkIcons/social-media.svg",
    page: "/marketing",
    description: "work_card_marketing_description",
  },
];

export const projectsTechnologies = [
  {
    value: 0,
    label: "projects_page_technologies_filter",
    icon: "/assets/SSNTAssets/technology.svg",
  },
  {
    value: "react",
    label: "React",
    icon: "/assets/SSNTAssets/TechnologiesIcons/react.svg",
    categoriesValue: ["web-dev", "mobile-dev", "business-apps"],
  },
  {
    value: "react-native",
    label: "React-Native",
    icon: "/assets/SSNTAssets/TechnologiesIcons/react-native.svg",
    categoriesValue: ["mobile-dev", "business-apps"],
  },
  {
    value: "laravel",
    label: "Laravel",
    icon: "/assets/SSNTAssets/TechnologiesIcons/laravel.svg",
    categoriesValue: ["web-dev", "mobile-dev", "business-apps"],
  },
  {
    value: "mysql",
    label: "MySql",
    icon: "/assets/SSNTAssets/TechnologiesIcons/mysql.svg",
    categoriesValue: ["web-dev", "mobile-dev", "business-apps"],
  },
  {
    value: "android",
    label: "Android",
    icon: "/assets/SSNTAssets/TechnologiesIcons/android.svg",
    categoriesValue: ["mobile-dev", "business-apps"],
  },
  {
    value: "ios",
    label: "IOS",
    icon: "/assets/SSNTAssets/TechnologiesIcons/ios.svg",
    categoriesValue: ["mobile-dev", "business-apps"],
  },
  {
    value: "wordpress",
    label: "WordPress",
    icon: "/assets/SSNTAssets/TechnologiesIcons/wordpress.svg",
    categoriesValue: ["web-dev"],
  },
];

export const ourProjectsConfig = [
  {
    name: "Website 2A Farm",
    categories: ["web-dev"],
    technologies: ["react", "laravel", "mysql"],
    description: "farm_project_description",
    image_1: "/assets/SSNTAssets/OurProjectsPictures/2afarm_image_1.png",
    image_2: "/assets/SSNTAssets/OurProjectsPictures/2afarm_image_2.png",
    image_3: "/assets/SSNTAssets/OurProjectsPictures/2afarm_image_3.png",
    sections: [
      {
        title: "farm_project_section_1_title",
        description: "farm_project_section_1_description",
        icon: "/assets/SSNTAssets/web3.svg",
        iconColor: "black",
      },
      {
        title: "farm_project_section_2_title",
        description: "farm_project_section_2_description",
        icon: "/assets/SSNTAssets/dashboard.svg",
        iconColor: "black",
      },
      {
        title: "farm_project_section_3_title",
        description: "farm_project_section_3_description",
        icon: "/assets/SSNTAssets/backend.svg",
        iconColor: "black",
      },
    ],
  },
  {
    name: "Warehouse Management System",
    categories: ["mobile-dev", "business-apps"],
    technologies: ["react", "react-native", "laravel", "android", "mysql"],
    description: "wms_project_description",
    image_1: "/assets/SSNTAssets/OurProjectsPictures/WMS_image_1.png",
    image_2: "/assets/SSNTAssets/OurProjectsPictures/WMS_image_2.png",
    image_3: "/assets/SSNTAssets/OurProjectsPictures/WMS_image_3.png",
    sections: [
      {
        title: "wms_project_section_1_title",
        description: "wms_project_section_1_description",
        icon: "/assets/SSNTAssets/dashboard.svg",
        iconColor: "black",
      },
      {
        title: "wms_project_section_2_title",
        description: "wms_project_section_2_description",
        icon: "/assets/SSNTAssets/mobile.svg",
        iconColor: "black",
      },
      {
        title: "wms_project_section_3_title",
        description: "wms_project_section_3_description",
        icon: "/assets/SSNTAssets/backend.svg",
        iconColor: "black",
      },
    ],
  },
  {
    name: "Transport Management System",
    categories: ["mobile-dev", "business-apps"],
    technologies: ["react", "react-native", "laravel", "android", "mysql"],
    description: "tms_project_description",
    image_1: "/assets/SSNTAssets/OurProjectsPictures/WMS_image_1.png",
    image_2: "/assets/SSNTAssets/OurProjectsPictures/WMS_image_1.png",
    image_3: "/assets/SSNTAssets/OurProjectsPictures/WMS_image_1.png",
    sections: [
      {
        title: "tms_project_section_1_title",
        description: "tms_project_section_1_description",
        icon: "/assets/SSNTAssets/dashboard.svg",
        iconColor: "black",
      },
      {
        title: "tms_project_section_2_title",
        description: "tms_project_section_2_description",
        icon: "/assets/SSNTAssets/web3.svg",
        iconColor: "black",
      },
      {
        title: "tms_project_section_3_title",
        description: "tms_project_section_3_description",
        icon: "/assets/SSNTAssets/mobile.svg",
        iconColor: "black",
      },
    ],
  },
  {
    name: "Mitliv Cranes Equipment",
    categories: ["web-dev"],
    technologies: ["wordpress"],
    description: "mitliv_project_description",
    image_1: "/assets/SSNTAssets/OurProjectsPictures/MITLIV_image_1.png",
    image_2: "/assets/SSNTAssets/OurProjectsPictures/MITLIV_image_2.png",
    image_3: "/assets/SSNTAssets/OurProjectsPictures/MITLIV_image_3.png",
    sections: [
      {
        title: "mitliv_project_section_1_title",
        description: "mitliv_project_section_1_description",
        icon: "/assets/SSNTAssets/web3.svg",
        iconColor: "black",
      },
      {
        title: "mitliv_project_section_2_title",
        description: "mitliv_project_section_2_description",
        icon: "/assets/SSNTAssets/photo-edit.svg",
        iconColor: "transparent",
      },
      {
        title: "mitliv_project_section_3_title",
        description: "mitliv_project_section_3_description",
        icon: "/assets/SSNTAssets/content.svg",
        iconColor: "black",
      },
    ],
  },
];
